import { useNavigate } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
const UsaidPg = () => {
  const navigate = useNavigate();

  const handlenavigate = () => {
    navigate("/application/usaid");
  };

  return (
    <>
      <div className="flex flex-col gap-5 mt-8 p-2 w-full justify-center items-center border-b pb-5">
        <div className="flex  flex-col lg:flex-row gap-3 pt-10 border-b pb-8 items-center lg:justify-around px-5 py-2 w-full">
          <img
            className="object-contain w-1/2"
            src="/usaid_logo.png"
            alt="usaid_logo"
          />
          <img
            className="lg:w-1/2  md:h-44 lg:h-40"
            src="/strong_village_programme.png"
            alt="usaid_logo"
          />
        </div>
        <button
          onClick={handlenavigate}
          className=" p-3 px-5 rounded-xl  hover:opacity-90  border bg-textColor  flex items-center justify-center gap-3 border-none  text-white font-bold text-2xl"
        >
          <DescriptionIcon sx={{ color: "#fff", fontSize: "40px" }} />
          განაცხადის შევსება
        </button>
      </div>
      <div className="w-full p-3 ">
        <div className="title mb-5 md:mb-0 border-b sm:border-none pb-2 md:pb-0 flex justify-start md:justify-center items-center md:m-3 md:ml-10 mr-10">
          <h2 className=" text-md font-bold  sm:text-lg  md:text-2xl text-textColor w-fit sm:pl-2 pr-2 bg-neutral-100 sm:font-medium  ">
            ახალი საწარმოები ადმინისტრაციულ გამყოფ ხაზთან მდებარე თემებში
          </h2>
        </div>

        <p className=" mt-8 sm:p-2 sm:text-lg text-md sm:mt-0">
          USAID-ის ძლიერი სოფლის პროგრამისა და სტარტაპ საქართველოს ერთობლივი
          ახალი ინიციატივის მიზანია, საქართველოს ადმინისტრაციულ გამყოფ ხაზთან
          მდებარე თემებში ინოვიციური ბიზნესების განვითარება ახალი
          შესაძლებლობების გაჩენის, შემოსავლების ზრდისა და ახალი სამუშაო
          ადგილების შექმნის მიზნით.
          <b className="pl-3">ინოვაციურად განიხილება ბიზნეს იდეა, რომელიც:</b>
        </p>

        <ul className="ml-8 sm:p-2 text-md sm:text-lg list-disc mt-3 sm:mt-0">
          <li>
            სთავაზობს ბაზარზე არსებული შეთავაზებისგან განსხვავებულ ახალ
            პროდუქტს, სერვისს ან ტექნოლოგიას
          </li>
          <li>
            იწვევს ცვლილებებს ტრადიციულ დარგებში არატრადიციული ბიზნეს მოდელის ან
            ტექნოლოგიის შემოტანით
          </li>
          <li>
            იყენებს თანამედროვე ტექნოლოგიებს პრობლემების უფრო ეფექტურად
            გადაწყვეტისთვის
          </li>
          <li>
            სთავაზობს მასშტაბურ გადაწყვეტას ბაზარზე არსებული მოთხოვნის
            დასაკმაყოფილებლად,
          </li>
          <li>სთავაზობს მომხმარებელზე ორიენტირებულ დიზაინს,</li>
          <li>სთავაზობს გარემოსდაცვითი პრობლემების ახლებურ გადაწყვეტას.</li>
        </ul>

        <p className="sm:p-2 sm:text-lg text-md mt-3 sm:mt-0">
          ბიზნეს იდეა უნდა განხორციელდეს გამყოფი ხაზის მიმდებარედ 11
          მუნიციპალიტეტის 94 თემში{" "}
          <span>
            <a
              className="text-textColor cursor-pointer hover:opacity-80"
              href="/samizne_temebi.pdf"
              target="_blank"
            >
              (იხ. ჩამონათვალი)
            </a>
          </span>
          .
          <br />
          <br />
          პროგრამა მოიცავს ორ ეტაპს: პრე-აქსელერაცია და ინვესტირების ეტაპი.
        </p>

        <p className="sm:p-2 sm:text-lg text-md mt-3 sm:mt-0">
          <b> პრე-აქსელერაციის ეტაპი:</b> მონაწილეები 4-6 კვირის განმავლობაში
          გაივლიან ტრენინგს და მიიღებენ მენტორულ დახმარებას იმისათვის, რომ
          საკუთარი იდეები აქციონ სრულფასოვან ბიზნესად. პრე-აქსელერაციის ეტაპის
          გავლის შემდეგ მეწარმეებმა უნდა შეძლონ სრულყოფილი ბიზნეს გეგმის
          შედგენა, რომლის განხილვაც მოხდება ინვესტირების ეტაპზე.
        </p>

        <p className="sm:p-2 sm:text-lg text-md mt-3 sm:mt-0">
          <b> ინვესტირების ეტაპი:</b> ინვესტირების ეტაპში მონაწილეობას მიიღებენ
          მხოლოდ ის მეწარმეები, რომლებიც წარმატებით გაივლიან პრე-აქსელერაციის
          ეტაპს. USAID-ის ძლიერი სოფლის პროგრამა და სტარტაპ საქართველო,
          დაფინანსების მიზნით, შეარჩევენ წარმატებულ ბიზნეს პროექტებს. სტარტაპ
          საქართველო განახორციელებს შეღავათიან გრძელვადიან ინვესტიციას კაპიტალში
          მონაწილეობის სახით, ხოლო USAID-ის ძლიერი სოფლის პროგრამა გასცემს
          საგრანტო დაფინანსებას. თითოეულ წარმატებულ განმცხადებელს მოეთხოვება
          ბიზნეს გეგმით გათვალისწინებული ბიუჯეტის მინიმუმ 10%-ით
          თანადაფინანსება. ბიუჯეტის დარჩენილ თანხას თანაბრად დააფინანსებს USAID-
          ის ძლიერი სოფლის პროგრამა და სტარტაპ საქართველო. მაგალითად: თუ ბიზნეს
          გეგმით გათვალისწინებული საერთო ბიუჯეტია 200 000 ლარი და განმცხადებლის
          თანამონაწილეობაა 20 000 ლარი, სტარტაპ საქართველოს ინვესტიცია შესაძლოა
          იყოს 90 000 ლარი, USAID-ის ძლიერი სოფლის პროგრამის გრანტი კი - 90 000
          ლარი.
        </p>

        <p className="sm:p-2 sm:text-lg text-md mt-3 sm:mt-0 border-b border-gray-400 pb-5">
          პრე-აქსელერაცია და ინვესტირება განხორციელდება ეტაპობრივად
          მუნიციპალიტეტების მიხედვით.
        </p>
        <p className="sm:p-2 sm:text-lg text-md sm:mt-0 mt-5 pt-10 ">
          პრე-აქსელერაციის პროგრამა{" "}
          <b>
            {" "}
            გორის, ქარელის, კასპის, ხაშურის, დუშეთისა და ყაზბეგის
            მუნიციპალიტეტების
          </b>{" "}
          სამიზნე თემებში.
          <br />
          <br />
          მონაწილოების მისაღებად გთხოვთ წარმოადგინოთ თქვენი ბიზნეს იდეა.{" "}
          <span
            className="underline font-bold text-textColor cursor-pointer hover:opacity-80"
            onClick={handlenavigate}
          >
            შეავსეთ განაცხადის ფორმა.{" "}
          </span>
          განაცხადების მიღების ბოლო ვადა- 2024 წ, 17 მაისი.
          <br />
          <br />
          განაცხადები შეფასდება შემდეგი კრიტერიუმებით: განაცხადის ინოვაციურობა,
          გუნდის გამოცდილება და კვალიფიკაცია.
          <br />
          <br />
          პრე-აქსელერაციის პროგრამა{" "}
          <b>
            საჩხერის, ონის, ზუგდიდის, წალენჯიხის, მესტიის მუნიციპალიტეტების
          </b>{" "}
          სამიზნე თემებში. განაცხადების მიღება იგეგმება 2024 წ. სექტემბერში.
        </p>
      </div>
    </>
  );
};

export default UsaidPg;
