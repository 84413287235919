export const data = [
  {
    id: 1,
    tittle: "ინოვაციების ეკოსისტემის განვითარების ხელშეწყობა",
    answer:
      '„სტარტაპ საქართველო" ინოვაციური ეკოსისტემის მნიშვნელოვანი ნაწილი გახდება. ეს გულისხმობს სახელმწიფოს, საგანმანათლებლო, სამეცნიერო და კერძო სექტორის აქტიურ თანამშრომლობას. ეს ქვეყანაში ცოდნასა და ინოვაციებზე დაფუძნებული ეკონომიკის მშენებლობასა და კერძო სექტორის კონკურენტუნარიანობის გაზრდას განაპირობებს.',
  },
  {
    id: 2,
    tittle: "სტარტაპ საწარმოს გუნდის კვალიფიკაციის ამაღლება",
    answer:
      '„სტარტაპ საქართველო", ტრენინგებისა და კონსულტაციების საშუალებით, პროგრამაში მონაწილეებს ხელს შეუწყობს იმ უნარჩვევებისა და კვალიფიკაციის ამაღლებაში, რაც მათი ბიზნესიდეის წარმატებით განხორციელებისთვისაა საჭირო. საწყის ეტაპზე, „სტარტაპ საქართველო" დაეხმარება დაფინანსებულ საწარმოებს (ე.წ მენტორინგის საშუალებით) კომპანიის განვითარებისათვის საჭირო გადაწყვეტილებების მიღებაშიც.',
  },
  {
    id: 3,
    tittle: "ფინანსური რესურსით მხარდაჭერა",
    answer:
      '„სტარტაპ საქართველო" დააფინანსებს კონკურსში გამოვლენილ საუკეთესო პროექტებს. კერძოდ, „სტარტაპ საქართველო", გამოყოფილი ბიუჯეტის ფარგლებში, 15,000 ლარიდან 100,000 ლარამდე ფინანსური რესურსით უზრუნველყოფს საუკეთესო ინოვაციური და სიცოცხლისუნარიანი ბიზნესიდეის მქონე პროგრამის მონაწილეებს.',
  },
  {
    id: 4,
    tittle: "სტარტაპ ჰაბის შექმნა",
    answer:
      '„სტარტაპ საქართველოს" მიზანია ხელი შეუწყოს მსგავსი ინტერესებისა და განსხვავებული გამოცდილების მქონე პირებისათვის საერთო სივრცის შექმნას.',
  },
];
