const ListItem = ({ img, item, text, border }) => {
  return (
    <li
      className={`w-full md:p-3 flex  items-center  pb-12  gap-3 ${
        !border ? " border-b border-gray-400 pb-12  gap-3" : ""
      }`}
    >
      <figure className="w-1/12 hidden md:block ">
        <img className="w-full h-full" src={img} alt="icon" />
      </figure>

      <div className="flex flex-col w-full md:w-10/12 items-start">
        <div className="flex items-center gap-3 mb-2 md:mb-0 border-b md:border-none pb-2 md:pb-0 w-full">
          <figure className="md:hidden w-14 h-14">
            <img className="w-full h-full" src={img} alt="icon" />
          </figure>
          <h3 className="text-md sm:text-lg text-textColor">{item}</h3>
        </div>
        <p className="text-md sm:text-lg">{text}</p>
      </div>
    </li>
  );
};

const TermsPg = () => {
  return (
    <>
      <div className="w-full p-3 border-b-2 sm:border-none ">
        <div className="title flex justify-start sm:justify-center items-center sm:m-3 md:ml-10 mr-10 border-b-2 sm:border-none">
          <h2 className="text-xl sm:text-2xl text-textColor w-fit sm:pl-2 pr-2 bg-neutral-100 font-medium  ">
            მონაწილეობის პირობები
          </h2>
        </div>

        <p className="text-sm sm:text-lg sm:p-2 mt-2 sm:mt-0">
          პროგრამის ფარგლებში შესაძლოა დაფინანსდეს ნებისმიერი ბიზნესსაქმიანობა,
          რომელიც არ არის აკრძალული საქართველოს კანონმდებლობით. „სტარტაპ
          საქართველო" პროექტების შერჩევის პროცესს წარმართავს კონკურსის წესით,
          წარმოდგენილი განაცხადებიდან საუკეთესო პროექტების შერჩევის გზით.
          „დამწყები საწარმოების (სტარტაპების) ხელშეწყობის პროგრამა"-ში
          მონაწილეობის მსურველი (განმცხადებელი) უნდა აკმაყოფილებდეს შემდეგ
          პირობებს:
        </p>

        <ul className="list-none p-2 text-sm sm:text-lg text-gray-600 flex flex-col gap-3 ">
          <li className="flex items-center gap-3">
            <img src="/icon.png" alt="icon" />
            განმცხადებელი უნდა იყოს საქართველოს მოქალაქე ფიზიკური პირი (ან პირთა
            ჯგუფი)
          </li>
          <li className="flex items-center gap-3">
            <img src="/icon.png" alt="icon" />
            წარმოდგენილი იდეა უნდა იყოს ინოვაციური და ჰქონდეს ბიზნეს პერსპექტივა
          </li>
          <li className="flex items-center gap-3">
            <img src="/icon.png" alt="icon" />
            განცხადებელს უნდა გააჩნდეს საკუთარი პროექტის განხორციელებისთვის
            საჭირო ხედვა და მზადყოფნა
          </li>
        </ul>
      </div>

      <div className="w-full p-3 flex flex-col">
        <div className="title mb-5 md:mb-0 flex justify-start md:justify-center items-center md:m-3 md:ml-10 mr-10">
          <h2 className=" text-sm font-bold  sm:text-lg  md:text-2xl text-textColor w-fit sm:pl-2 pr-2 bg-neutral-100 sm:font-medium  ">
            პროექტების შერჩევისა და განხორციელების პროცესი
          </h2>
        </div>

        <ul className="w-full xl:w-4/5 pt-3 mt-3 md:p-5  xl:self-center  flex flex-col gap-3 list-none">
          <ListItem
            img={"/p_1.png"}
            item="განაცხადის შევსება"
            text='    განმცხადებელს „სტარტაპ საქართველოს" ვებგვერდის საშულებით შეუძლია
                წარადგინოს ბიზნესიდეა და სხვა დამატებით მოთხოვნილი დოკუმენტაცია.
                იმ შემთხვევაში, თუკი განმცხადებელი დარწმუნებულია, რომ მისი იდეა
                წარმოადგენს მაღალტექნოლოგიურ ბიზნესიდეას და ორიენტირებულია
                გლობალურ ბაზარზე, განმცხადებელი ავსებს მაღალტექნოლოგიური
                კომპონენტის განაცხადის ფორმას, სხვა შემთხვევაში, განმცხადებელი
                ავსებს ინოვაციური კომპონენტის განაცხადს." '
          />
          <ListItem
            img={"/p_2.png"}
            item="განმცხადებელთან კომუნიკაცია/შეხვედრა"
            text="  კითხვების არსებობის შემთხვევაში მოხდება განმცხადებელთან დაკავშირება და/ან განხორციელდება ვიზიტი პროექტის სავარაუდო ადგილმდებარეობის, ან სხვა კომპონენტების დასათვალიერებლად. "
          />
          <ListItem
            img={"/p_2.png"}
            item="განმცხადებელთან კომუნიკაცია/შეხვედრა"
            text="  კითხვების არსებობის შემთხვევაში მოხდება განმცხადებელთან დაკავშირება და/ან განხორციელდება ვიზიტი პროექტის სავარაუდო ადგილმდებარეობის, ან სხვა კომპონენტების დასათვალიერებლად. "
          />
          <ListItem
            img={"/p_3.png"}
            item="განაცხადის განხილვა"
            text='შემოსულ განაცხადებს განიხილავავენ „სტარტაპ საქართველოს" წარმომადგენლები, ხდება ბიზნეს იდეის საბოლოო ფორმით ჩამოყალიბება (მათ შორის, საჭიროების შემთხვევაში, განმცხადებლის მონაწილეობით) და „სტარტაპ საქართველოსთან" არსებული კომიტეტისთვის წარდგენა. განხილვა მიზნად ისახავს თითოეული მიღებული განაცხადის ინოვაციურობისა და პერსპექტიულობის შეფასებას.'
          />
          <ListItem
            img={"/p_4.png"}
            item="კომიტეტის გადაწყვეტილება"
            text='კომიტეტი აფასებს პროექტის ინოვაციურობას, ეფექტიანობასა და მდგრადობას, იღებს გადაწყვეტილებას პროექტის კონკურსში გამარჯვებულის გამოვლენის შესახებ. კომიტეტის მიერ მიღებული გადაწყვეტილება უნდა დამტკიცდეს სს „საპარტნიორო ფონდის" სამეთვალყურეო საბჭოს მიერ.'
          />
          <ListItem
            img={"/p_5.png"}
            item="კონკურსში გამარჯვებული პროექტების გამოცხადება"
            text='„სტარტაპ საქართველო" ყოველთვიურად გამოაქვეყნებს კომიტეტის მიერ გამარჯვებულად მიჩნეული პროექტების სიას.'
          />
          <ListItem
            img={"/p_6.png"}
            item="ტრენინგი"
            text="  განმცხადებლებს, რომლებიც ვერ მიიღებენ დაფინანსებას, მათივე სურვილისამებრ, ჩაუტარდებათ ტრენინგი ბიზნესგანათლებისა და ბიზნესის მართვის კვალიფიკაციის ამაღლების კუთხით. ამასთან, აღნიშნულ კონკურსანტებს შეუძლიათ ხელახლა წარადგინოს ახალი, ან იგივე იდეა მომდევნო კონკურსში. "
          />
          <ListItem
            img={"/p_7.png"}
            item="პროექტის დაფინანსება"
            text=' პროგრამის ფარგლებში საინვესტიციო დაფინანსებას იღებს იურიდიულ პირი, რომლის დამფუძნებლებიც არიან გამარჯვებული განმცხადებელი (ან განმცხადებელთა ჯგუფი) და „სტარტაპ საქართველო“. ამისათვის ხდება ახალი კომპანიის რეგისტრაცია, ან შესაძლებელია უკვე არსებულის დაფინანსებაც, თუ მისი რეგისტრაციიდან განაცხადის წარმოდგენის თარიღამდე არ არის გასული 6 თვეზე მეტი.
            თანხის ჩარიცხვამდე განმცხადებელთან ფორმდება პარტნიორთა შეთანხმება, რომლითაც განისაზღვრება „სტარტაპ საქართველოდან" დაფინანსების მიღების წესი და წინაპირობები, ასევე მხარეთა უფლება-მოვალეობები. '
          />
          <ListItem
            img={"/p_8.png"}
            item="მენტორინგი"
            text="  საჭიროების შემთხვევაში კონკურსში გამარჯვებულ განმცხადებლებს პროექტის დაწყებამდე, ან იმპლემენტაციის პროცესში კონსულტაციას გაუწევენ სხვადასხვა გამოცდილებისა და ცოდნის მქონე მოწვეული ადგილობრივი და უცხოელი ექსპერტები. "
          />
          <ListItem
            img={"/p_9.png"}
            item="მონიტორინგი"
            text='  თითოეული დაფინანსებული პროექტი ხდება „სტარტაპ საქართველოს" მხრიდან მონიტორინგის საგანი. მონიტორინგის მიზანია გამოყოფილი თანხების ათვისების მიზნობრიობის კონტროლი, პროექტის ბიზნესგეგმის შესრულების მონიტორინგი, მენეჯმენტის ეფექტიანობის მონიტორინგი და პროექტის წარმატებით განხორციელებისთვის აუცილებელი სხვა პირობების შესრულების მონიტორინგი.'
          />
          <ListItem
            img={"/p_10.png"}
            item="ერთობლივი საწარმოდან გამოსვლა"
            text="პარტნიორთა შეთანხმებით დადგენილ ვადებში და პირობებით განმცხადებელი ახორციელებს წილის გამოსყიდვას, რის შემდეგაც ხდება ერთობლივი საწარმოდან „სტარტაპ საქართველოს“ გამოსვლა. "
            border={true}
          />
        </ul>
      </div>
    </>
  );
};

export default TermsPg;
