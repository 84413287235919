export const vilages2 = [
  'აჭარა',
  'გურია',
  'იმერეთი',
  'თბილისი',
  'კახეთი',
  'მცხეთა-მთიანეთი',
  'რაჭა-ლეჩხუმი, ქვემო სვანეთი',
  'სამეგრელო-ზემოსვანეთი',
  'სამცხე ჯავახეთი',
  'ქვემო ქართლი ',
  'შიდა ქართვლი',
];



export const villages = [
  "გორის მუნიციპალიტეტის თემები",
  "ახალუბანი",
  "ბერბუკი",
  "დიცი",
  "ძევერა",
  "კარალეთი",
  "ქვახრელი",
  "მეხვრისხევი",
  "მერეთი",
  "ნიქოზი",
  "შავშვები",
  "შინდისი",
  "ტირძნისი",
  "ტყვიავი",
  "ვარიანი",
  "ზეღდულეთი",
  "ონის მუნიციპალიტეტის თემები",
  "გლოლა (სოფელი)",
  "ღარი",
  "გომი",
  "პიპილეთი",
  "შეუბანი",
  "წედისი",
  "ცხმორი",
  "უწერა",
  "წალენჯიხის მუნიციპალიტეტის თემები",
  "ჭალე",
  "ჩქვალერი",
  "ეწერი",
  "ჯვარი (ქალაქი)",
  "ლია",
  "მუჟავა",
  "ფახულანი",
  "საჩინო",
  "წალენჯიხა (ქალაქი)",
  "ზუგდიდის მუნიციპალიტეტის თემები",
  "ახალი აბასთუმანი (სოფელი)",
  "ახალკახეთი (სოფელი)",
  "ახალსოფელი",
  "ანაკლია",
  "ჭითაწყარი",
  "ჭკადუაში (სოფელი)",
  "ჩხორია",
  "დარჩელი",
  "დარჩელი",
  "დიდინეძი",
  "ერგეტა",
  "განმუხური",
  "ინგირი",
  "კახეთი (სოფელი)",
  "კოკი",
  "კორცხელი",
  "ოდიში",
  "ოქტომბერი",
  "ორსანთია",
  "ორულუ",
  "რიყე (სოფელი)",
  "რუხი",
  "შამგონა (სოფელი)",
  "ციაში",
  "ზუგდიდი (ქალაქი)",
  "მესტიის მინიციპალიტეტის თემები",
  "ჭუბერი",
  "ხაიში",
  "უდლიანი",
  "დუშეთის მუნიციპალიტეტის თემები",
  "ანანური",
  "ბაზალეთი",
  "ჭართალი",
  "ჭონქაძე",
  "გრემისხევი",
  "გუდამაყარი",
  "ქვეშეთი",
  "მჭადიჯვარი",
  "ფასანაური (დაბა)",
  "საჩხერის მუნიციპალიტეტის თემები",
  "არგვეთი",
  "ჭალა",
  "ჭალოვანი",
  "ჩიხა",
  "ყაზბეგის მუნიციპალიტეტის თემები",
  "გუდაური",
  "კობი",
  "ქარელის მუნიციპალიტეტის თემები",
  "აბისი",
  "აგარა (დაბა)",
  "ავლევი",
  "ბრეძა",
  "ბრეთი",
  "დირბი (სოფელი)",
  "დვანი",
  "გიგანტი",
  "მოხისი",
  "ფცა",
  "კასპის მუნიციპალიტეტის თემები",
  "დოესი კოდისწყარო",
  "ქვემო ჭალა",
  "ლამისყანა",
  "მეტეხი",
  "ოკამი",
  "სამთავისი",
  "ხაშურის მუნიციპალიტეტის თემები",
  "ალვი",
  "პლევი",
  "წაღვლი",
];

export const munisipalities = [
  "გორი",
  "ქარელი",
  "კასპი",
  "ხაშური",
  "ონი",
  "ზუგდიდი",
  "წალენჯიხა",
  "მესტია",
  "დუშეთი",
  "საჩხერე",
];

export const regions = [
  "შიდა ქართლი",
  "რაჭა-ლეჩხუმ ქვემო სვანეთი",
  "სამეგრელო ზემო სხვანეთი",
  "მცხეთა-მთიანეთი",
  "იმერეთი",
];

export const industries = [
  "ტურიზმი",
  "სასტუმრო ბიზნესი",
  "ვაჭრობა",
  "მშენებლობა",
  "ენერგეტიკა",
  "ტელეკომუნიკაცია",
  "სამედიცინო მომსახურება",
  "სოფლის მეურნეობა",
  "ინფორმაციული ტექნოლოგიები",
  "საფინანსო საქმიანობა",
  "განათლება და სპორტი",
  "ტრანსპორტირება და ლოგისტიკა",
  "რესტორნები, ბარები და სასადილოები",
  "საკვები პროდუქტების წარმოება",
  "ალკოჰოლური და არა-ალკოჰოლური სასმელების წარმოება",
  "ელექტრომოწყობილობების წარმოება",
  "სამშენებლო მასალების წარმოება",
  "საფეიქრო წარმოება",
  "ქიმიური მრეწველობა",
  "ფარმაცევტიკა",
  "პლასტმასები, კაუჩუკი, რეზინი და მათი ნაწარმის წარმოება",
  "მერქნის და მერქნის ნაწარმის წარმოება",
  "ხე-ტყის გადამუშავება და მათი ნაწარმის წარმოება",
  "არა-ძვირფასი ლითონები და მათი ნაწარმის წარმოება",
  "ძვირფასი/ნახევრადძვირფასი ქვების/ლითონების და ბიჟუტერიის წარმოება",
  "სატრანსპორტო საშუალებების, საფრენი აპარატების და მცურავი საშუალებების წარმოება",
  "სხვა წარმოება",
  "სხვა მომსახურება",
];

export const positions = ["პარტნიორი", "მენეჯერი", "დასაქმებული"];
